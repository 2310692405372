.App {
    background-color: #FFFFFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #075916;
}

.App-home {
    text-align: center;
}

img.App-logo {
    max-width: 80vw;
    max-height: 40vh;
}

.App-social {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    padding: 0.3rem;
    align-items: center;
    outline-color: #075916;
}

img.insta-logo {
    height: calc(10px + 2vmin);
    margin-right: 0.3rem;
    flex-wrap: nowrap;
}

p.App-slogan {
    font-size: 1rem;
    letter-spacing: 0.3rem;
    color: #5c0501;
}
